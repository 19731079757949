
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        































@import "~@/less/common.less";
#app:not(.promotion) {.bgc(#171717);
  .btn-holder { .rel() ; .pt(20); .mb(40);
    .error { .abs(); .lt(50%, -10); .t-xc(); .c(@c-red;) }
  }
  .contents-wrapper { .c(white); .crop(); .min-h(var(--innerHeight));
    .contents-holder { .p(40, 28, 0); .-box(); .tl(); .max-w(600); .mh-c(); }
  }
  @media (@tl-up) {
    .contents-wrapper {
      .contents-holder { .ph(0);
        &.tracking { .max-w(1000);}
      }
    }
  }
  &.white { .bgc(white);
    .contents-wrapper {
      .contents-holder { .max-w(none); .p(0); }
    }
  }
}
