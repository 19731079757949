@import "env.less";

@max: ms mm ml tp tl ds dm;
@min: mm ml tp tl ds dm dl;

each(@max,{
  @w: 'screen-@{value}-max';
  @media (max-width: @@w) {
    .hidden-@{value}-down { display: none !important; }
  }
});

each(@min,{
  @w: 'screen-@{value}-min';
  @media (min-width: @@w) {
    .hidden-@{value}-up { display: none !important; }
  }
});
