
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        

























@import '~@/less/proj.less';
[ggpass-tnc-container] { .tl(); .fs(14); .c(#D4D4D4);
  .container { .ph(16); .max-w(100%); .mh-c(); }
  .key-visual { .rel(); .h(352); .bg('https://ssl.gg-global-cdn.com/game/platform-front/img/widget/key_visual_Legal_d.png'); .bg-c(); .cover(); .c(#fff);
    &::before { .cnt(); .abs(); .lt(0, 0); .wf(); .h(40%); background: linear-gradient(180deg, #000 0%, rgba(255, 255, 255, 0.00) 100%); background-blend-mode: multiply; }
    &::after { .cnt(); .abs(); .lb(0, 0); .wf(); .h(40%); background: linear-gradient(0deg, #000 -76.16%, rgba(0, 0, 0, 0.00) 72.92%); }
    .container { .flex(); .hf(); .items-end(); }
    h2 { text-transform: uppercase; }
  }
  h2 { .z(1); .fs(36); .bold(); .mb(30); line-height: 1.2; }
  h3 { .fs(18); .medium(); }
  .contents-body { .pv(56); .-t(transparent, 2px); border-image: linear-gradient(90deg, rgba(0,0,0,0.3) 10%, rgba(211,0,0,0.8) 50%, rgba(0,0,0,0.3) 90%); border-image-slice: 1;
    pre { white-space: pre-wrap; line-height: 1.8; }
    ul { .pl(24);
      li { list-style: disc; }
    }
    table { border-collapse: collapse; .-a(#6C6C7A);
      th { .medium(); }
      th, td { .w(50%); .p(10, 12); .-a(#6C6C7A); }
    }
  }

  @media (@mm-up) {
    .container { .ph(40); }
  }
  @media (@tp-up) {
    .container { .max-w(784); }
    .key-visual { .h(528); }
    h2 { .fs(60); .mb(72); }
    .contents-body { .pv(80); }

  }
  @media (@ds-up) {
    .container { .max-w(1280); }
    .key-visual { .h(544); }
    h2 { .fs(68); .mb(112); }
    h3 { .fs(20); }
    .contents-body { .pv(128); }
  }
}
