
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        









































































































@import '~@/less/proj.less';
[sso-post] { .rel(); .h(var(--innerHeight));
  > div { .abs(); .lt(50%, 50%); .t-xyc();
    [font-icon] { .fs(60); .block(); .c(@c-red); }
    [color-button] { .w(240); .fs(20); .medium(); .h(48); }
    .desc { .m(36, 0, 100); .fs(20, 32); }
    &.success {
      [font-icon] { .c(@c-success); }
    }
  }
  .btn-ghost { .abs(); .wh(0, 0); .lt(-100, -100); }
}
