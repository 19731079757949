@import "asset.less";
@import "vars.less";

.input-btn-x { .wh(24); .rt(30, 50%); .t-yc; .abs; .z(4); .br(50%); .bgc(@c-b02); .flex-center();
  svg { .wh(12); }
}

.GOLD {
  [drop-list] {
    .list-holder {
      .list-box {
        .item {
          &.active { .bgc(@c-gold); }
        }
      }
    }
  }
}

[drop-list] {.fix(); .lt(0, 0); .w(100%); .tr-o(.2s); .t-y(-10); .hidden();  pointer-events: none; .o(0);
  &.black {
    .list-box { .bgc(rgba(31, 34, 36, 1)); .-a(rgba(31, 34, 36, 1));}
  }
  &.dark {
    .list-holder { .mt(6); box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5);
      .list-box { .bgc(#1e1e1e); .-a();
        .item {
          &:hover { .bgc(@c-b02);}
        }
        li { .fs(12);
          i { .fs(12); .c(white);}
          &.active { .bgc(@c-red); .c(white); }
        }
      }
    }
  }
  &.animated { transition: none;
  }
  &.on, &.down {
    .list-box { .br-b(8); }
  }
  &.up {
    .list-box { .br-b(0); }
  }
  .list-holder { .h(100%);
    .list-box { .crop; .p(8, 10); .bgc(#fff); .-a(#eee); overflow-y: auto; .h(100%);
      .item { .p(3, 10); .fs(13, 28); .pointer; .c(#787781); .ellipsis();
        &:not(:nth-of-type(1)) { .-t(@c-b01);}
        &.check-all { .-b(@c-b01); .mb(4);}
        &:hover { .bgc(#999); .c(white); }
        &.none-data { .p(15, 0); cursor: default; .justify-center();
          &:hover { .bgc(transparent); }
        }
        &.active { .c(white); }
      }
      .tree-item { .fs(14); .pointer();
        &.hide { .p(0); .-t();
          > .item-holder { .hide(); }
          > * {.p(0); }
        }
        .item-holder { .flex(); .items-center(); .pl(6);
          > * { .p(6, 0); }
          //&:hover { .bgc(#eee);}
        }
        .children { .pl(20);
          .tree-item {
          }
        }
      }
    }
  }
  &.active { .visible(); pointer-events: all; .o(1);}
  [checkbox-item] { .wh(100%);}
  .active { .bgc(@c-mint); .c(white); }
  &::-webkit-scrollbar { .w(0); }
  &::-webkit-scrollbar-track { .bgc(transparent); }
  &::-webkit-scrollbar-thumb { .bgc(transparent); .br(2); }
  &::-webkit-scrollbar-thumb:hover { .bgc(transparent); }
  .drop-multi-line { .fs(14,14); .medium(); .pv(3);
    >p { .fs(13,16); white-space: normal; .mt(6); .regular(); }
  }
}


.plus {
  >.icon { .wh(100%);
    background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0), transparent;
    background-position:center;
    background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
  }
}
.minus {
  >.icon { .wh(100%);
    background: linear-gradient(#fff 0 0), transparent;
    background-position:center;
    background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
  }
}

.angle.icon { .abs();
  border-top: 5px solid #000;
  border-right: 5px solid #000;
  transform: rotate(315deg);
  &.up {}
}