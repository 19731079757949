
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        













































































































@import '~@shared/less/asset.less';

[modal-container] { .fix; .wh(100vw, var(--innerHeight)); .lt; .z(5000);
  @keyframes c1 {
    0% {.o(1); .t-s(1);}
    50% {.o(0); .t-s(.5);}
    100% {.o(1); .t-s(1);}
  }
  > div { .z(1); }
  .blocker { .abs; .f; .flex-center; .z(1000);
    > .dim { .abs; .f; .bgc(rgba(0, 0, 0, 0.7)); .lt; }
    [lottie]:not(.lottie-coaching) { .wh(250); }
    .lottie-coaching {.wh(200)}
    .seq-preloader { .ib-list; .abs; .lt(50%, 50%); .t-xyc; .z(2);
      em {.wh(10); .m(0, 8); .bgc(white); .br(30); animation-name: c1; animation-duration: 2s; animation-iteration-count: infinite;
        &:nth-of-type(2) {.wh(12); .mt(-1); animation-delay: .2s;}
        &:nth-of-type(3) {.wh(14); .mt(-2); animation-delay: .4s;}
        &:nth-of-type(4) {.wh(16); .mt(-3); animation-delay: .6s;}
        &:nth-of-type(5) {.wh(14); .mt(-2); animation-delay: .8s;}
      }
    }
  }
  .fade-enter-active { .tr-o(0.5s, 0.3s); }
  .fade-leave-active { .tr-o(0.5s, 0.3s); }
  .fade-enter, .fade-leave-to { .o(0) }
  &.clear { .wh(0, 0); }
}
