
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        
























@import '~@/less/proj.less';
[main] {
  >.contents-body > .container > img { .w(500); .rel(); .t(-280); }
}
