@import "~@shared/less/asset.less";
@import "~@shared/less/responsive.less";
@import "vars.less";

.focus() {.-a(@c-yellow, 2); }
.submit-group {
  .flex();
  .flex-dc();
  .gap(10);

  @media (@ml-up) {
    .flex-dr();
  }
}