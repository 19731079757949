
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        























@import "~@shared/less/proj.less";
[validation-errors] { .c(@c-red); .mt(4); .tl();
  > li { .fs(14);
    a[href^="mailto:"] { .c(@c-link); }
  }
}
