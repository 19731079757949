
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        



























































































































































































































@import '~@shared/less/asset.less';

[popover-container] { .abs; .lt; .z(5100); .tl();
  .popover-position { .fix;
    .popover { .abs;
      .component-crop { .p(10); .fs(13, 18); .c(rgba(255, 255, 255, 0.5)); .bgc(#0e1415); .-a(rgba(255, 255, 255, 0.1)); .br(4); .crop(); .rel(); .z(2); .h(); overflow-y: auto;
        &.use-component { .p; }
        &.has-close { .pr(30) }
      }
      .arrow { .wh(7); .bgc(#0e1415); .-t(rgba(255, 255, 255, 0.1)); .-l(rgba(255, 255, 255, 0.1)); .-b(); .abs; .z(1); }
      &.r .arrow { transform: rotate(315deg); .l(-2); }
      &.t .arrow { transform: rotate(225deg); .b(-2); }
      &.l .arrow { transform: rotate(135deg); .r(-2); }
      &.b .arrow { transform: rotate(315deg); .t(-2); }

      &.vl .arrow { .l(var(--target-center)); }
      &.vc .arrow { .l(calc(50% - 5px)) }
      &.vr .arrow { .r(var(--target-center)); }

      &.ht .arrow { .t(var(--target-middle)); }
      &.hc .arrow { .t(calc(50% - 5px)) }
      &.hb .arrow { .b(calc(100% - 20px)); }

      .popover-close { .abs; .rt(8, 7); .pointer; }
      .popover-text { .c(white); }
      &.use-component {
        .component-crop { .p; .-a(rgba(0, 0, 0, .1)); .br(8); .bgc(transparent); }
        &.arrow { .bgc(#3A3957); .-t(rgba(173, 168, 227, 0.4)); .-l(rgba(173, 168, 227, 0.4)); }
      }
    }
  }
  .theme {}
}
