
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        















































































































@import '~@/less/proj.less';
[sso] {.rel(); .h(var(--innerHeight));
  .holder { .abs(); .lt(50%,50%); .t-xyc();
    >p { .fs(20); .mb(40); }
    [color-button] { .w(320); .fs(20); .medium(); .h(48); .br(40); }
  }
}
