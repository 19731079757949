@charset "utf-8";
body {-webkit-text-size-adjust: none;word-wrap: break-word;-webkit-tap-highlight-color: transparent}
* {margin: 0;padding: 0;max-height: 1000000px;box-sizing: border-box;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section {display: block}
fieldset, iframe, img, table, td, textArea, tr {border: 0}
caption, legend {display: none}
table {width: 100%;border: 0;border-spacing: 0;border-collapse: collapse}
table th {font-weight: 400}
textarea {-webkit-appearance: none;-webkit-border-radius: 0;border: 0;resize: none}
ol, ul {list-style: none}
hr {border: none;height: 0}
i {font-style: normal}
s {text-decoration: none}
em {font-style: normal}
a {cursor: pointer;color: inherit;text-decoration: none}
q::after, q::before, q:after, q:before {display: none;content: none}
button {border: 0;cursor: pointer}
input, select {-webkit-appearance: none;-webkit-border-radius: 0;vertical-align: middle}
input:checked[type=checkbox] {background-color: #666;-webkit-appearance: checkbox}
input[type=search]::-webkit-search-cancel-button {-webkit-appearance: none}
input[type=submit] {cursor: pointer}
select::-ms-expand {display: none}
input::-ms-clear{ display: none}
img {-webkit-touch-callout: none;-ms-user-select: none;user-select: none;-webkit-user-drag: none;-ms-user-drag: none;user-drag: none;object-fit: cover;object-position: center}
*:focus {outline:0}
