@import 'proj.less';

.GOLD {
  #app:not(.promotion) { .bg('@{img}/bg_pattern.png');
    .field-holder {
      .holder { .bgc(rgba(255,255,255,0.07)); }
    }
  }
}
#app:not(.promotion) .title { .fs(28); text-shadow: 1px 1px 1px #000; .medium(); .mv(80);
  >* {.block(); .fs(20); .regular(); .mt(8); .c(#7f7f7f);}
}
