@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src:
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Bold.woff2') format('woff2'),
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src:
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Medium.woff2') format('woff2'),
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src:
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Regular.woff2') format('woff2'),
      url('https://static.goodchoice.kr/fonts/pretendard/Pretendard-Regular.woff') format('woff');
}
