
          @SCREEN_MM: 420;
          @SCREEN_ML: 580;
          @SCREEN_TP: 768;
          @SCREEN_TL: 1024;
          @SCREEN_DS: 1260;
          @SCREEN_DM: 1420;
          @SCREEN_DL: 1600;
        









































































@import '~@shared/less/proj.less';

.GOLD {
  [toast-container] {
    > div {
      &.success { .bgc(@c-gold); }
    }
  }
}
[toast-container] { .fix; .wf; .lb(0, 20); .z(10000); .fs(14); .medium();
  > div { .abs; .lb(50%, 0); .wh(90%, auto); .o(1); .min-h(30); .flex-center; .tr-to(0.5s); .t-xy(-50%, 150%); .p(18, 0); .c(white); .br(30);
    [font-icon] { .ib(); .mr(4);}
    &.success { .bgc(@c-secondary); }
    &.fail { .bgc(@c-red); .c(white);
      * { .ib() !important;}
    }
    &.out { .o(0); }
    [font-icon] { .ib; .vam; .fs(20); .mr(5); .c(white);}
    span { .ib; .vam; }
  }
}
