@import 'base.less';
@import 'proj.less';
@import 'site.less';
@import '~@shared/less/font.less';
@import '~@shared/less/responsive.less';
@import '~@shared/less/mixin.less';

html { .fs(16,24); .bgc(#171717); }
body { .h(100%); }
* { word-break: keep-all; word-wrap: break-word; -webkit-overflow-scrolling: touch; font-family: 'Roboto', @font-pre, sans-serif; font-size: inherit;
  &::-webkit-scrollbar { .w(4); }
  &::-webkit-scrollbar-track { .bgc(transparent); }
  &::-webkit-scrollbar-thumb { .bgc(@c-base-gray); .br(2); }
  &::-webkit-scrollbar-thumb:hover { .bgc(rgba(0,0,0,.2)); }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

li {list-style: none;}
label { }
a {text-decoration: none; .pointer();
  label { pointer-events: none;}
  &:focus, &:active, &:hover, &:visited {outline: none;outline: 0;text-decoration: none;}
}
img {.vam;}
input { outline: none; .-a();}
.swiper-container {.w(100%);
  .swiper-wrapper {.w(100%);}
}

button { .bgc(transparent); }

.edit-holder { .p(20, 0, 0);
  fieldset > div, > div { .block(); .tl();
    &:not(:nth-of-type(1)) { .-t(@c-b02); .pt(12); .mt(12); }
    > label { .fs(14); .tl(); .bold(); .mb(8); .block();}
    .item-holder { .w(100%); .tl(); .fs(14);
      > * {
        &:not(:nth-of-type(1)) { .mt(8); }
      }
    }
  }
  @media (@tp-up) {
    fieldset > div, > div { .flex();
      &:not(:nth-of-type(1)) { .-t(@c-b02);}
      > label { .w(140); .mb(0); }
      .item-holder { .w(calc(100% - 140px)); }
    }
  }
}

.fields-holder {
  > * {
    &:not(:nth-of-type(1)) { .mt(8); }
  }
}

.no-list { .w(100%); .p(20); .br(8); .bgc(@c-b01); .tc(); .fs(14); .semi-bold(); .c(@c-b05) }

.tooltip-list { .fs(12, 20); .tl();
  h5 { .semi-bold(); .fs(14); .mb(4);}
  p { .pl(10); .rel();
    &:before { .cnt(); .abs(); .wh(4, 4); .br(50%); .lt(0, 50%); .t-yc(); .bgc(@c-w05); }
  }
}

.error-msg { .c(@c-red); .ml(12); .mt(4); .fs(14, 16);}
.error-exp {
  h5 { .fs(14, 16); .mb(2); .semi-bold(); }
  li { .fs(13); }
}
.space-between { .flex(); .space-between(); .tl();
  > * { .flex-grow(1);
    &:nth-of-type(1) { .w(50%); }
  }
}
.id-error {
  > label { .block(); .semi-bold(); .mb(4); }
  > span { .pl(4); }
}


/** COLOR */
.bgc-color {
  &.red { .bgc(@c-red);
    &.round { }
  }
  &.light-blue { .bgc(@c-bluePale); }
  &.blue { .bgc(@c-link); }
  &.green { .bgc(@c-green); }
  &.gray { .bgc(@c-text-gray); }
}
.font-color {
  &.red { .c(@c-red); }
  &.light-blue { .c(@c-bluePale); }
  &.blue { .c(@c-link); }
  &.green { .c(@c-green); }
  &.gray { .c(@c-text-gray); }
}

.input-field { .br(6); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .-box(); .-a(transparent, 2); .bgc(rgba(39, 39, 39, 0.2)); .min-h(48); }
.btn-update-field { .ml(4); .fs(14); .-b(@c-w03); .c(@c-w03); .medium(); .mt(8); .ib();
  &:hover { .c(@c-red); .-b(@c-red); .tr-d(.3); }
}
.list-star { .p(15, 0); .fs(16); .c(#7f7f7f); .lh(20); .rel(); .pl(12);
  &:before { content: '*'; .abs(); .lt(0, 18); }
}

#grecaptcha { .hide(); }
